

.RS-faq {
  width: 100%;
  padding: unset;
  flex-direction: column;
  display: flex;
}

.RS-faq__single {
  gap: var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  display: flex;
  position: relative;
}

.RS-faq__single:after {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  background-color: var(--RS-light-grey-color);
  border-radius: var(--RS-space-2);
  bottom: calc(0px + var(--RS-space-1-n));
  position: absolute;
}

.RS-faq__single-question {
  cursor: pointer;
  padding: var(--RS-space-24) var(--RS-space-8);
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .RS-faq__single-question {
    padding: var(--RS-space-24);
  }
}

.RS-faq__single-answer {
  gap: var(--RS-space-16);
  padding: 0 var(--RS-space-8) var(--RS-space-32) var(--RS-space-8);
  flex-direction: column;
  display: none;
}

@media (min-width: 768px) {
  .RS-faq__single-answer {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-faq__single-answer--expanded {
  display: flex;
}

.RS-faq__single-answer p {
  line-height: var(--RS-line-height-xlg);
  font-size: var(--RS-font-size-p-desktop);
}

.RS-faq-icon {
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  margin-right: unset;
  transform: translateY(var(--RS-space-8));
}

@media (min-width: 768px) {
  .RS-faq-icon {
    margin-right: var(--RS-space-16);
  }
}

.RS-faq-icon--expanded {
  transform: translateY(var(--RS-space-8)) rotateZ(180deg);
}



/*# sourceMappingURL=index.4203f453.css.map */
